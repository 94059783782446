import {FormEvent, Fragment, useState} from "react";

import {registerPasswordReset} from "./auth-api";
import {AxiosError} from "axios";

export const RegisterFirstPasswordPage = () => {

    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        registerPasswordReset(email)
            .then(() => {
                    setSubmitted(true);
                    setError(null);
                },
                (error: AxiosError) => {
                    setError(error);
                })
    };

    if (submitted) {
        return (<Fragment>
            <h3>Set your first password</h3>
            <p>✅ Email sent.</p>
            <p>Follow the link in the email to continue.</p>
            <p>Allow a few minutes for the email to arrive. Any problems, please ask Todd.</p>
        </Fragment>)
    }

    return (
        <form onSubmit={handleSubmit}>
            <h3>Set your first password</h3>
            <label>
                Email:
            </label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="username"
                required
            />
            <button type="submit">Submit</button>
            {error && (
                error?.response?.status === 422 ?
                    <p>This email can't be accepted. If you think this is wrong, please get in touch.</p>
                    : <p>An error occurred. Please try again.</p>
            )}
            <p className={'mt-3'}>You'll get an email with a link to continue.</p>
            <p>Use the same email address that you used for the predictions survey. If you're unsure, ask Todd.</p>
            <p>Already have an account? <a href="/login">Log in</a></p>
        </form>
    );

}