import { Data } from "./dataTypes";

export const getUsers = (data: Data) => data.users;

export const getUser = (data: Data, userId: string) => data.users.filter(user => user.id === userId)[0]

export const getQuestions = (data: Data) => data.questions;

export const getQuestion = (data: Data, questionId: string) => data.questions.filter(question => question.id === questionId)[0];

export const getPrediction = (data: Data, userId: string, questionId: string) => data.responses
.filter(userResponse => userResponse.userId === userId)[0]
.answers.filter(answer => answer.questionId === questionId)[0]
.prediction;