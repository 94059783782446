const FAIL_COLOR = {r: 220, g: 53, b: 69};
const FIFTY_PERCENT_COLOR = {r: 255, g: 193, b: 7};
const SUCCESS_COLOR = {r: 40, g: 167, b: 69};
const NO_OUTCOME_COLOR = 'rgb(23, 162, 184)'

const interpolateColor = (start: any, end: any, factor: number) => {
    const r = Math.round(start.r + (end.r - start.r) * factor);
    const g = Math.round(start.g + (end.g - start.g) * factor);
    const b = Math.round(start.b + (end.b - start.b) * factor);
    return `rgb(${r}, ${g}, ${b})`;
};
const getColorForSuccess = (percentage: number) => {
    if (percentage <= 50) {
        return interpolateColor(FAIL_COLOR, FIFTY_PERCENT_COLOR, percentage / 50)
    } else {
        return interpolateColor(FIFTY_PERCENT_COLOR, SUCCESS_COLOR, (percentage - 50) / 50)
    }
};
export const getPredictionColor = (prediction: number, outcome: 'true' | 'false' | 'none') => {
    if (outcome === 'none') {
        return NO_OUTCOME_COLOR;
    }
    return outcome === 'true'
        ? getColorForSuccess(prediction)
        : getColorForSuccess(100 - prediction)
}