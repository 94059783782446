import {useMemo} from "react";
import {HorizontalBarChart} from "./HorizontalBarChart";
import {getPredictionColor} from "./colors";

export interface PredictionsBarChartProps {
    outcome: 'true' | 'false' | 'none';
    predictions: {
        prediction: number;
        label: string;
    }[],
    sorted?: boolean
}

export const PredictionsBarChart = ({ outcome, predictions, sorted }: PredictionsBarChartProps) => {
    const predictionsInOrder = useMemo(() => {
        if (!sorted) {
            return predictions;
        }
        const sortedPredictions = [...predictions];
        sortedPredictions.sort((a, b) => a.prediction -b.prediction);
        return sortedPredictions;
    }, [predictions, sorted])

    return (
        <HorizontalBarChart data={predictionsInOrder.map(
            prediction => ({
                label: prediction.label,
                value: prediction.prediction,
                color: getPredictionColor(prediction.prediction, outcome)
            })
        )} valueMax={100} valueSuffix={'%'}/>
    );
};