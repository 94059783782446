import request from "axios";
import {Data, QuestionResponse} from "./dataTypes";
import {AUTH_TOKEN_KEY} from "./auth-api";
import {getApiHost} from "./config";

export const fetchData = async (): Promise<Data> => (await request.get("data.json")).data;

export const fetchDataV2 = async (): Promise<Data | null> => {

    const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenMaybe) {
        return Promise.resolve(null);
    }
    const parsedToken = `${JSON.parse(tokenMaybe)}`;
    return (await request
        .get(getApiHost() + "/predictions/data",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": parsedToken
                }
            })).data;
};

export const fetchQuestions = async (): Promise<QuestionResponse[] | null> => {

    const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenMaybe) {
        return Promise.resolve(null);
    }
    const parsedToken = `${JSON.parse(tokenMaybe)}`;
    return (await request
        .get(getApiHost() + "/questions",
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": parsedToken
                }
            })).data;
};

export const join2025Contest = async (): Promise<void> => {
    const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenMaybe) {
        return Promise.resolve();
    }
    const parsedToken = `${JSON.parse(tokenMaybe)}`;
    return (await request
        .post(getApiHost() + "/questions",
            {},
            {
                headers: {
                    "Authorization": parsedToken
                }
            }));
}

export const updatePrediction = async (questionId: string, prediction: number): Promise<void> => {
    const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenMaybe) {
        return Promise.resolve();
    }
    const parsedToken = `${JSON.parse(tokenMaybe)}`;
    return (await request
        .put(getApiHost() + "/questions/" + questionId,
            {
                prediction: prediction
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": parsedToken
                }
            }));
}

