import {FormEvent, Fragment, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {createNewPassword} from "./auth-api";
import {AxiosError} from "axios";

export const ResetPasswordPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const emailBase64 = queryParams.get("email");
    const token = queryParams.get("token");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<AxiosError | null>(null);

    if (!emailBase64 || !token) {
        return <p>Broken link. Please try again. <a href={"/register-password-reset"}>Register</a></p>
    }

    const email = atob(emailBase64);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        createNewPassword(
            email, password, token
        )
            .then(() => {
                    navigate("/login")
                },
                (error: AxiosError) => {
                    setError(error);
                })
    };

    return <Fragment>
        <h2>Set password</h2>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email: </label>
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                readOnly
                autoComplete="username"
                style={{border: 'none'}}
            />
            <br/>
            <label className={'mt-2'}>
                Password:
            </label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            {' '}
            <button type="submit">Set new password</button>
            {error && (
                <p>Error. Please check your details and try again. Remember this link only works for a short amount of
                    time, and you can only use it once. <a
                        href={`/register-password-reset?email=${emailBase64}`}>Request a new link</a> if necessary.</p>
            )}
            <div className="mt-4">
                <p>It goes without saying, but:</p>
                <ul>
                    <li>Don't use a password you've used somewhere else.</li>
                    <li>Use a password manager to remember all your <i>different</i> passwords, like a book or <a
                        href='https://1password.com/'>1Password</a>.
                    </li>
                </ul>
            </div>
            {error && (
                <div>
                    <p>
                        Already set your password? <a href={"/login"}>Log in</a>
                    </p>
                </div>
            )}
        </form>

    </Fragment>
}