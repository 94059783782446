import React, {Fragment} from "react";
import {getPrediction, getQuestions, getUsers} from "./dataApi";
import {Accordion, Card} from "react-bootstrap";
import {PredictionsBarChart} from "./PredictionsBarChart";
import {getAveragePrediction} from "./dataAnalytics";
import {useQuery} from "react-query";
import {fetchDataV2} from "./api";
import {Question} from "./dataTypes";
import {computeSurpriseScore} from "./score";
import {getPredictionColor} from "./colors";

const getExplainer = (answer: number, outcome: 'true' | 'false') => {
    const surprise = (answer > 60 && outcome === 'false') || (answer < 40 && outcome === 'true');
    const lowSurprise = (answer > 60 && outcome === 'true') || (answer < 40 && outcome === 'false');
    const answerExplainer = answer > 60 ? 'You thought this would happen' : answer < 40
        ? 'You didn\'t think this would happen' : 'You weren\'t sure whether this would happen';
    const conjunction = surprise ? 'but' : 'and';
    const outcomeExplainer = outcome === 'true' ? 'it did happen' : 'it didn\'t happen';
    const scoreDescriptor = surprise ? 'high' : lowSurprise ? 'low' : 'neutral';

    return `${answerExplainer}, ${conjunction} ${outcomeExplainer}, so you get a ${scoreDescriptor} surprise score`;
}

const QuestionCard = ({userId, question}: { userId: string, question: Question }) => {
    const {data} = useQuery(['prediction-data'], fetchDataV2);
    if (!data) {
        return <></>
    }
    const answer = getPrediction(data, userId, question.id);
    return <Accordion.Item eventKey={'predictions-' + question.id}>
        <Accordion.Header>
            <Card.Title>{question.title}{' '}
                {question.outcome !== 'none' ?
                    <Fragment>
                    <span
                        className="badge bg-primary rounded-pill">{question.outcome === 'true' ? 'True' : 'False'}</span>
                        {' '}
                        <span className="badge rounded-pill"
                              style={{backgroundColor: getPredictionColor(answer, question.outcome)}}>Answer: {answer}% (Score: {computeSurpriseScore(answer, question.outcome)})</span>
                    </Fragment> :
                    <span className="badge rounded-pill"
                          style={{backgroundColor: getPredictionColor(answer, question.outcome)}}>
                        Answer: {answer}%
                    </span>
                }
            </Card.Title>
        </Accordion.Header>
        <Accordion.Body>
            <h4>Key info</h4>
            <ul>
            {question.outcomeText && <li>Outcome: {question.outcome === 'true' ? 'True' : question.outcome === 'false' ? 'False' : 'None'}. {question.outcomeText}</li>}
            <li>Your prediction: {answer}%</li>
            {question.outcome !== 'none' && <li>
                {getExplainer(answer, question.outcome)}: {computeSurpriseScore(answer, question.outcome)}
            </li>}
            </ul>
            <h4>Additional context</h4>
            <ul>
            {question.subtitle && <li>Original context: {question.subtitle}</li>}
            <li>Average guess: {getAveragePrediction(data, question.id)}%</li>
            </ul>
            <h4>All results</h4>
            <PredictionsBarChart outcome={question.outcome}
                                 predictions={getUsers(data).map(user => ({
                                     label: user.name,
                                     prediction: getPrediction(data, user.id, question.id)
                                 }))}
                                 sorted
            />
        </Accordion.Body>
    </Accordion.Item>;
}

export const QuestionList = ({userId}: { userId: string }) => {
    const {data} = useQuery(['prediction-data'], fetchDataV2);
    if (!data) {
        return <></>
    }
    return <div className={'mb-5'}>
        <h2 className={'mb-4 mt-5'}>Your responses</h2>
        <h3 className={'mb-4'}>Predictions that have resolved</h3>
        <Accordion>
            {getQuestions(data)
                .filter(question => question.outcome !== 'none')
                .map(question =>
                    <QuestionCard userId={userId} question={question} key={question.id}/>)
            }
        </Accordion>
            <h3 className={'mb-4 mt-5'}>Predictions that haven't resolved yet</h3>
        <Accordion>
            {getQuestions(data)
                .filter(question => question.outcome === 'none')
                .map(question =>
                    <QuestionCard userId={userId} question={question} key={question.id}/>)
            }
        </Accordion>
    </div>
}