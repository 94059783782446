import {FormEvent, Fragment, useState} from "react";

import {registerPasswordReset} from "./auth-api";
import {AxiosError} from "axios";
import {useLocation} from "react-router-dom";

export const RegisterPasswordResetPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailBase64 = queryParams.get("email");

    const [email, setEmail] = useState(emailBase64 ? atob(emailBase64) : "");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        registerPasswordReset(email)
            .then(() => {
                    setSubmitted(true);
                    setError(null);
                },
                (error: AxiosError) => {
                    setError(error);
                })
    };

    return (
        <Fragment>
            <h2>Request password reset</h2>

            {submitted ? (
                <Fragment>
                    <p>✅ Email sent.</p>
                    <p>Follow the link in the email to continue.</p>
                    <p>Allow a few minutes for the email to arrive. Any problems, please ask Todd.</p>
                </Fragment>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email: </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="username"
                    />
                    <br/>
                    <button className={"mt-2"} type="submit">Request password reset</button>
                    {error && (
                        error?.response?.status === 422 ?
                            <p>This email can't be accepted. If you think this is wrong, please get in touch.</p>
                            : <p>An error occurred. Please try again.</p>
                    )}
                    <p className={"mt-3"}>Already have an account? <a href="/login">Log in</a></p>
                </form>
            )}
        </Fragment>
    )
        ;

}