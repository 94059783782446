import {Fragment} from "react";
import {useQuery} from "react-query";
import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY, fetchUser} from "./auth-api";
import {LogOutButton} from "./LogOutButton";

export const UserSettingsPage = () => {
    const [authToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const {isLoading, data: user} = useQuery(
        ["identity"],
        (_context) => fetchUser(),
        {
            enabled: !!authToken,
            retry: false,
            // refetchInterval TODO explore these options
        }
    );

    if (isLoading) {
        return <p>Loading ...</p>
    }

    if (!user) {
        return <Fragment><p>Not logged in!</p>
            <a href={"/login"}>Log in</a>{' '}
            <a href={"/register"}>Register</a>
        </Fragment>;
    }

    return <Fragment><p>Logged in as {user.displayName}</p>
        <LogOutButton />
    </Fragment>
}