import React, {Fragment} from "react";
import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY, fetchUser} from "./auth-api";
import {Container, Navbar} from "react-bootstrap";
import {LogOutButton} from "./LogOutButton";
import {useQuery} from "react-query";
import {fetchDataV2} from "./api";
import {AxiosError} from "axios";


export const HomePage = () => {
    const [authToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const {data: user} = useQuery(["identity"], (_context) => fetchUser(),
        {
            enabled: !!authToken,
            retry: false
        })

    const {error: predictionsResponseError} = useQuery(['prediction-data'], fetchDataV2, {
        retry: false,
        enabled: !!user
    });
    const show2024 = !((predictionsResponseError as AxiosError)?.response?.status === 403)

    return (<Fragment>
            <Container className="PageContentWidth">
                <Navbar className="justify-content-between">
                    <Navbar.Brand><a href={"/"}
                                     className={"text-decoration-none text-reset"}>Predictions</a></Navbar.Brand>
                    {user && <div>Logged in as {user.displayName} <LogOutButton/></div>}

                </Navbar>

                <h1 className="text-center mt-3 mb-5">Todd's prediction contests</h1>
                <p>
                    Hello and welcome 👋
                </p>

                <p>
                    This is the home of Todd's annual prediction contest.
                </p>

                <p>
                    Each December, we each try to work out the probability of various events which may or may not happen
                    over the following year.
                </p>

                <p>
                    Then at the end of the year, we mark which events happened, and which did not, to find out whose
                    predictions were best.
                </p>

                {!user && <p>
                    To take part, you need an account: <a href={"/register"}>create an account</a> or <a
                    href={"/login"}>log in</a>.
                </p>}

                {user && <Fragment>

                    {show2024 && <p><a href={"/predictions"}>View 2024 results</a></p>}
                    <p><a href={"/questions-2025"}>Go to 2025 contest</a></p>
                </Fragment>}
            </Container>
        </Fragment>
    );
}