import { ProgressBar } from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";

export interface Props {
    data: {
        value: number;
        label: string;
        color?: Color
    }[],
    valueMax: number,
    valueSuffix?: string
}

export const HorizontalBarChart = ({ data, valueMax, valueSuffix }: Props) => {
    return (
        <div className="horizontal-bar-chart">
            <div className="bars-container">
                {data.map((barData, index) => {
                    const valueAsPercent = barData.value / valueMax * 100
                    const valueLabelOutside = valueAsPercent < 10;
                    return (
                        <div className="bar-row" key={index}>
                            <div className="bar-label">{`${barData.label}`}</div>
                            <div className="bar-wrapper">
                                <ProgressBar>
                                    <ProgressBar
                                        now={valueAsPercent}
                                        label={!valueLabelOutside && `${barData.value}${valueSuffix || ''}`}
                                        style={{ backgroundColor: barData.color}}
                                    />
                                    {valueLabelOutside &&
                                        <ProgressBar now={30}
                                            label={`${barData.value}${valueSuffix || ''}`}
                                            className="bar-value-label-outside" />
                                    }
                                </ProgressBar>
                            </div>
                        </div>);
                })}
            </div>
        </div>
    );
};