import React, {Fragment} from "react";
import {QuestionList} from "./QuestionList";
import {Container, Navbar} from "react-bootstrap";
import {useQuery} from "react-query";
import {fetchDataV2} from "./api";
import {Leaderboard} from "./Leaderboard";
import {LogOutButton} from "./LogOutButton";
import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY, fetchUser} from "./auth-api";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";

const TITLE = 'Prediction contest 2024 mid-year results'

export const UserPageV2 = () => {
    const [authToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const {data, isLoading, error} = useQuery(['prediction-data'], fetchDataV2, {retry: false});
    const {data: user, isLoading: userLoading} = useQuery(["identity"], (_context) => fetchUser(),
        {
            enabled: !!authToken,
            retry: false
        })
    const userId = user?.userId;
    const navigate = useNavigate();

    if (!authToken) {
        navigate("/login");
    }

    if (isLoading || userLoading) {
        return <Fragment><p>Loading...</p>
            <LogOutButton/></Fragment>;
    }

    if ((error as AxiosError)?.response?.status === 403) {
        return <p>You weren't part of this prediction contest. <LogOutButton/></p>
    }

    if (!data || !user) {
        return <Fragment><p>Can't fetch data. Make sure you're logged in. <a href={"/login"}>Log in</a></p>
            <LogOutButton/></Fragment>;
    }

    return <Container className="PageContentWidth">
        <Navbar className="justify-content-between">
            <Navbar.Brand><a href={"/"} className={"text-decoration-none text-reset"}>Predictions</a></Navbar.Brand>
            <div>Logged in as {user.displayName} <LogOutButton/></div>

        </Navbar>

        {userId && <>
            <h1 className="text-center mt-3 mb-5">{TITLE}</h1>
            <p>
                Thanks for participating in the 2024 prediction contest.
                Here are the mid-year results.
            </p>
        </>}
        {userId && <Leaderboard userId={userId}/>}
        {userId && <QuestionList userId={userId}/>}
    </Container>;
}