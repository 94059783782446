import {FormEvent, Fragment, useState} from "react";

import {registerEmail} from "./auth-api";
import {AxiosError} from "axios";

export const RegisterPage = () => {

    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        registerEmail(email)
            .then((response) => {
                setSubmitted(true);
                setError(null);
            },
                (error: AxiosError) => {
                setError(error);
                })
    };

    if (submitted) {
        return (<p>Registration email sent. Follow instructions within.</p>)
    }

    return (
        <Fragment>
            <h2>Create your account</h2>
            {submitted ? (
                <Fragment>
                    <p>✅ Email sent.</p>
                    <p>Follow the link in the email to continue.</p>
                    <p>Allow a few minutes for the email to arrive. Any problems, please ask Todd.</p>
                </Fragment>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email: </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="username"
                    />
                    <br/>
                    <button className={"mt-2"} type="submit">Register</button>
                    {error && (
                        error?.response?.status === 422 ?
                            <p>This email can't be accepted. If you think this is wrong, please get in touch.</p>
                            : <p>An error occurred. Please try again.</p>
                    )}
                    <p className={"mt-3"}>Already have an account? <a href="/login">Log in</a></p>
                </form>
            )}
        </Fragment>
    );

}