import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY} from "./auth-api";
import {useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";

export const LogOutButton = () => {
    const [, , removeAuthToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return <button onClick={() => {
        removeAuthToken();
        queryClient.invalidateQueries().then(() => {
            navigate("/")
        })
    }}>Log out
    </button>
}