import {Data} from "./dataTypes";
import {getPrediction} from "./dataApi";

export const computeSurpriseScore = (answer: number, outcome: 'true' | 'false'): number => {
    const surpriseForTrue = (prob: number): number => {
        if (prob <= 0 || prob >= 100) {
            throw new Error("Probability must be between 0 and 100.");
        }
        const score = -Math.log(prob / 100) / -Math.log(0.5);
        return roundScore(score);
    };

    return outcome === 'true' ? surpriseForTrue(answer) : surpriseForTrue(100 - answer);
};

const roundScore = (score: number) => Math.round(score * 100) / 100;

export interface LeaderboardEntry {
    userId: string;
    userName: string;
    averageScore: number;
    position: number;
}

export const computeLeaderboard = (data: Data): LeaderboardEntry[] => {
    const numberOfQuestions = data.questions.filter(question => question.outcome !== 'none').length
    const rawUserScores = data.users
        .map(user => {
            const averageScore: number = data.questions
                .map(question => question.outcome === 'none' ? null : computeSurpriseScore(getPrediction(data, user.id, question.id), question.outcome))
                .filter(score => score !== null)
                .map(score => (score as number) / numberOfQuestions)
                .reduce((acc, curr) => acc + curr, 0)
            return {
                userId: user.id,
                userName: user.name,
                averageScore: roundScore(averageScore)
            }
        });
    const rawScores = rawUserScores.map(userScore => userScore.averageScore)
    const enrichedScores = rawUserScores
        .map(raw => ({...raw, position: rawScores.filter(otherScore => otherScore < raw.averageScore).length + 1}))

    enrichedScores.sort((a, b) => {
        // Primary sort: position
        if (a.position !== b.position) {
            return a.position - b.position;
        }
        // Secondary sort: name (to make deterministic)
        return a.userName.localeCompare(b.userName);
    });

    return enrichedScores;
}