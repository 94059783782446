import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY} from "./auth-api";
import {Navigate} from "react-router-dom";

export const RedirectFirstTime = () => {
    const [authToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);

    if (!authToken) {
        return <Navigate to='/first-password' />
    } else {
        return <Navigate to='/predictions' />
    }
}