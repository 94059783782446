import request from "axios";
import {UserResponse} from "./LoginPage";
import {getApiHost} from "./config";

export const AUTH_TOKEN_KEY = "authToken";
export const fetchUser = async (): Promise<UserResponse | null> => {

    const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenMaybe) {
        return Promise.resolve(null);
    }
    const parsedToken = `${JSON.parse(tokenMaybe)}`;
    return (await request
        .get(getApiHost() + "/identity/user",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": parsedToken
                }
            })).data;
};

export const registerEmail = async (email: string): Promise<void> => {
    return (await request
        .post(getApiHost() + "/identity/user-request",
            {email: email},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }));
};

export const registerPasswordReset = async (email: string): Promise<void> => {
    return (await request
        .post(getApiHost() + "/identity/password-request",
            {email: email},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }));
};

export const createNewPassword = async (email: string, password: string, timeBasedToken: string): Promise<void> => {
    return (await request
        .post(
            getApiHost() + "/identity/password",
            {email: email, password: password, timeBasedToken: timeBasedToken},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ))
}

export const createAccount = async (email: string, password: string, displayName: string, timeBasedToken: string): Promise<void> => {
    return (await request
        .post(
            getApiHost() + "/identity/user",
            {email: email, password: password, displayName: displayName, timeBasedToken: timeBasedToken},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ))
}