import {useQuery} from "react-query";
import {fetchDataV2} from "./api";
import React, {useMemo} from "react";
import {computeLeaderboard, LeaderboardEntry} from "./score";
import {Accordion} from "react-bootstrap";
import {HorizontalBarChart} from "./HorizontalBarChart";


export const Leaderboard = ({userId}: { userId: string }) => {
    const {data} = useQuery(['prediction-data'], fetchDataV2);
    const leaderboard = useMemo(() => data && computeLeaderboard(data), [data])
    if (!data || !leaderboard) {
        return <></>
    }
    const userEntry = leaderboard.find(entry => entry.userId === userId) as LeaderboardEntry;

    return <>
        <h2 className={'mt-5'}>Leaderboard</h2>
        <p>
            You're in position {userEntry.position} out of {leaderboard.length}.
        </p>
        <p>
            This is computed using <i>surprise scores</i>, which measure how surprised you are at what
            happened.
        </p>
        <ul>
            <li>
                Lower scores mean you gave accurate predictions.
            </li>
            <li>
                Higher scores mean you gave inaccurate predictions, so you'll be surprised at what actually happened.
            </li>
            <li>
                Anything less than 1 means you did better than just guessing 50% on everything.
            </li>
        </ul>
        <p>
            Your overall score was {userEntry.averageScore}.
        </p>
        <Accordion>
            <Accordion.Item eventKey={'leaderboard'}>
                <Accordion.Header>
                    See full leaderboard
                </Accordion.Header>
                <Accordion.Body>
                    <HorizontalBarChart data={leaderboard.map(entry => ({
                        value: entry.averageScore,
                        label: entry.userName,

                    }))} valueMax={leaderboard[leaderboard.length - 1].averageScore + 0.1}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </>
}